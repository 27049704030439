<template>
	<div class="tv">
		<div class="slider">
			<div class="swiper-container gallery-top">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(img, i) in list" :key="i">
						<img :src="API + img.filepath" alt="">
						<div class="watermark">{{ img.watermark }}</div>
					</div>
				</div>
			</div>
			<div class="swiper-container gallery-thumbs">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(img, i) in list" :key="i">
						<!-- :style="{ backgroundImage: 'url(' + API + img + ')' }" -->
						<img :src="API + img.filepath" alt="">
					</div>
				</div>
			</div>
		</div>

		<div class="info">
			<img class="logo" src="../assets/logo.png" alt="">
			<div class="content">
				<div class="btns">
					<div class="btn" @click="$router.push('/welcome')">
						<img class="icon" src="../assets/keyboard.png" alt="">
						<div class="enter">
							共创
						</div>
					</div>
					<!-- <div class="btn">
						<img class="icon" src="../assets/wechat.png" alt="">
						<img class="qr" src="../assets/qr.png" alt="">
					</div> -->
				</div>
				<!-- <div class="tips">扫码或点击“共创”，即代表同意《10000条龙共创协议》和《隐私保护指引》</div> -->
			</div>
		</div>
	</div>
</template>
  
<style lang="scss" scoped>
.swiper-container {
	width: 100%;
	height: 300px;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;
	background: #fff;
	position: relative;
	img {
		max-width: 100%;
		max-height: 100%;
	}
	.watermark{
		width: 100%;
		position: absolute;
		right: 0;
		bottom: 20px;
		text-align: center;
		color: #fff;
		text-shadow: 2px 2px 2px #333;
	}
}

.gallery-top {
	height: 90%;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.gallery-thumbs {
	height: 10%;
	box-sizing: border-box;
	padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
	width: 6%;
	height: 100%;
	opacity: 0.4;
	background-size: cover;
	background-position: center;
}

.gallery-thumbs .swiper-slide-active {
	opacity: 1;
}
</style>
<script>
export default {
	data() {
		return {
			list: []
		};
	},

	created() {
		this.getList()
	},
	async mounted() {
		await this.getList()

		var galleryTop = new Swiper('.gallery-top', {
			spaceBetween: 10,
			effect: 'fade',
			loop: true,
			autoplay: 5000,
			speed: 1000,
			autoplayDisableOnInteraction: false
		});
		var galleryThumbs = new Swiper('.gallery-thumbs', {
			spaceBetween: 10,
			centeredSlides: true,
			slidesPerView: 'auto',
			touchRatio: 0.2,
			// loop: true,
			// loopedSlides :8,
			slideToClickedSlide: true
		});
		galleryTop.params.control = galleryThumbs;
		galleryThumbs.params.control = galleryTop;



	},
	methods: {
		async getList() {
			const r = await this.$axios.post('/getLatestRecord')
			console.log(r, 29929298);

			if (r.status == 1) {
				this.list = r.result
			}
		}
	}
};
</script>

